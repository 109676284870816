import Vue from 'vue';
import App from './App.vue';
import router from './router';
Vue.config.productionTip = false;

import '../public/css/materialdesignicons.min.css';
import 'swiper/swiper-bundle.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'sweetalert2/dist/sweetalert2.min.css';

import {BootstrapVue} from 'bootstrap-vue';
const VueScrollTo = require('vue-scrollto');
import Scrollspy from 'vue2-scrollspy';
import VueYoutube from 'vue-youtube';
import vueVimeoPlayer from 'vue-vimeo-player';
import VueMasonry from 'vue-masonry-css';
import SimpleVueValidation from 'simple-vue-validator';
import VueSweetalert2 from 'vue-sweetalert2';

Vue.use(VueScrollTo, {
  duration: 3000,
  easing: 'ease',
});

Vue.use(BootstrapVue);
Vue.use(Scrollspy);
Vue.use(VueYoutube);
Vue.use(vueVimeoPlayer);
Vue.use(VueMasonry);
Vue.use(SimpleVueValidation);
Vue.use(VueSweetalert2);

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
