import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/home/Home'),
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/about/About'),
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/contact/Contact'),
  },
  {
    path: '/services/development',
    name: 'Development',
    component: () => import('../views/services/Development'),
  },
  {
    path: '/services/hosting',
    name: 'Hosting',
    component: () => import('../views/services/Hosting'),
  },
  {
    path: '/services/consulting',
    name: 'Consulting',
    component: () => import('../views/services/Consulting'),
  },
  {
    path: '*',
    component: () => import('../views/Error')
  },
];


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {x: 0, y: 0};
  },
});

export default router;
